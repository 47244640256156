/**
 * Wrapper component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import "./layout.scss"
import GlobalNav from "./global-nav.js"
const Wrapper = ({ children }) => {
  return <StaticQuery
      query={graphql`
        query TvQuery {
          file(relativePath: { eq: "bg-pattern.png" }) {
            publicURL
          }
        }
      `}

      render={ data => {
        return ( <React.Fragment>
          <div style={{
            position: "fixed",
            width: "100vw",
            top: 0,
            zIndex: 100,
            height: "100vh",
            transform: "translateX(-100vw)",
            backgroundImage: `url(${data.file.publicURL})`
          }} id="transition-canvas"> </div>
          {children}
        </React.Fragment>
      ) }
    }
  />
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Wrapper
