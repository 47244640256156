exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-death-js": () => import("./../../../src/pages/death.js" /* webpackChunkName: "component---src-pages-death-js" */),
  "component---src-pages-fraud-js": () => import("./../../../src/pages/fraud.js" /* webpackChunkName: "component---src-pages-fraud-js" */),
  "component---src-pages-greetings-js": () => import("./../../../src/pages/greetings.js" /* webpackChunkName: "component---src-pages-greetings-js" */),
  "component---src-pages-growth-js": () => import("./../../../src/pages/growth.js" /* webpackChunkName: "component---src-pages-growth-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mathmusic-js": () => import("./../../../src/pages/mathmusic.js" /* webpackChunkName: "component---src-pages-mathmusic-js" */),
  "component---src-pages-vangogh-js": () => import("./../../../src/pages/vangogh.js" /* webpackChunkName: "component---src-pages-vangogh-js" */),
  "component---src-templates-template-alphago-js": () => import("./../../../src/templates/template-alphago.js" /* webpackChunkName: "component---src-templates-template-alphago-js" */),
  "component---src-templates-template-default-js": () => import("./../../../src/templates/template-default.js" /* webpackChunkName: "component---src-templates-template-default-js" */),
  "component---src-templates-template-hypercard-js": () => import("./../../../src/templates/template-hypercard.js" /* webpackChunkName: "component---src-templates-template-hypercard-js" */),
  "component---src-templates-template-image-audio-sequence-js": () => import("./../../../src/templates/template-image-audio-sequence.js" /* webpackChunkName: "component---src-templates-template-image-audio-sequence-js" */),
  "component---src-templates-template-site-switcher-js": () => import("./../../../src/templates/template-site-switcher.js" /* webpackChunkName: "component---src-templates-template-site-switcher-js" */),
  "component---src-templates-template-slideshow-js": () => import("./../../../src/templates/template-slideshow.js" /* webpackChunkName: "component---src-templates-template-slideshow-js" */),
  "component---src-templates-template-turn-js": () => import("./../../../src/templates/template-turn.js" /* webpackChunkName: "component---src-templates-template-turn-js" */),
  "component---src-templates-template-tvgrid-js": () => import("./../../../src/templates/template-tvgrid.js" /* webpackChunkName: "component---src-templates-template-tvgrid-js" */),
  "component---src-templates-template-vg-letter-js": () => import("./../../../src/templates/template-vg-letter.js" /* webpackChunkName: "component---src-templates-template-vg-letter-js" */),
  "component---src-templates-template-videowall-js": () => import("./../../../src/templates/template-videowall.js" /* webpackChunkName: "component---src-templates-template-videowall-js" */)
}

