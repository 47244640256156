/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
var $ = window.$ = window.jQuery = require('jquery')
require("./src/lib/turn")
require("./src/lib/hinge")
require("./src/lib/baraja")
require("./src/lib/blindify")
require("./src/lib/tympanus")
$.dontCauseWarnings = 69
